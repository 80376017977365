import React, { Component, useMemo } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import { FieldLocationAutocompleteInput, FieldTextInput } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './LocationFilter.module.css';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useHistory, useLocation } from 'react-router-dom';
import { expandBoundingBox } from '../../../util/search';
import { parse } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'address';
};

const identity = v => v;


class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.shortKeywordTimeout = null;
    this.mobileInputRef = React.createRef();
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const urlParam = getKeywordQueryParam(queryParamNames);

    const hasInitialValues =
      !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'KeywordFilter.labelSelected' },
          { labelText: initialValues[urlParam] }
        )
      : label;


    const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
    const labelForPlain = <span className={labelClass}>{label}</span>;

    const filterText = intl.formatMessage({ id: 'KeywordFilter.filterText' });
    const placeholder = intl.formatMessage({ id: 'KeywordFilter.placeholder' });

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: initialValues[urlParam] };


    const handleSubmit = values => {
      const { history, routeConfiguration, currentSearchParams } = this.props;

      const location = values?.location;
      const selectedPlace = location?.selectedPlace || {};
      const boundsWithRadius = selectedPlace.bounds
        ? expandBoundingBox(
          selectedPlace.address === currentSearchParams.address ? currentSearchParams.placeBounds : selectedPlace.bounds,
          currentSearchParams.pub_radius
        ) : null;

      const searchParams = {
        address: !!location?.selectedPlace?.address
          ? location?.selectedPlace?.address
          : null,
        bounds: boundsWithRadius,
        placeBounds: selectedPlace.bounds || null,
        origin: !!location?.selectedPlace?.origin
          ? location?.selectedPlace?.origin
          : null,
        ...(location && { pub_radius: +currentSearchParams.pub_radius } ),
      };

      selectedPlace.address && !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    };

    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      if (this.mobileInputRef && this.mobileInputRef.current) {
        this.mobileInputRef.current.value = '';
      }
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >

        <FieldLocationAutocompleteInput
          inputClassName={css.locationAutocompleteInput}
          iconClassName={css.locationAutocompleteInputIcon}
          autoFocus={false}
          name="location"
          placeholder={intl.formatMessage({
            id: 'AdvancedSearch.locationPlaceholder',
          })}
          useDefaultPredictions={false}
          format={identity}
          // valueFromForm={this.state.currentLocation}
          // validate={composeValidators(autocompletePlaceSelected(addressNotRecognizedMessage))}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        onSubmit={handleSubmit}
        onClear={handleClear}
        initialValues={namedInitialValues}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>
          <label className={css.fieldPlainLabel} htmlFor={`${id}-input`}>
            {filterText}
          </label>
          <FieldLocationAutocompleteInput
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            autoFocus={false}
            name="location"
            placeholder={intl.formatMessage({
              id: 'AdvancedSearch.locationPlaceholder',
            })}
            useDefaultPredictions={false}
            format={identity}
            // valueFromForm={values.location}
            // validate={composeValidators(autocompletePlaceSelected(addressNotRecognizedMessage))}
          />
        </fieldset>
      </FilterPlain>
    );
  }
}

LocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

LocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilter);
